<template>
  <CIDataTable
    :vuex="vuex"
    :settings="settings"
    :route="route"
    model="achievements"
  />
</template>
<script>
import { achievements } from '@/config/descriptions/achievements';
import { achievementsTexts } from '@/config/pageTexts/achievements.json';

export default {
  name: 'AchievementsTable',
  data() {
    return {
      vuex: {
        getter: {
          theme: 'theme/theme',
          data: 'achievements/achievements',
          fields: 'achievements/fields',
          sortableFields: 'achievements/sortableFields',
          pages: 'achievements/pages',
          loading: 'achievements/loading',
          resultCount: 'achievements/resultCount',
          totalRecords: 'achievements/totalRecords',
          originalFields: 'achievements/originalFields',
          searchableAllFields: 'achievements/searchableAllFields',
          success: 'achievements/success',
          message: 'achievements/message',
        },
        actions: {
          fields: 'achievements/handleFields',
          delete: 'achievements/handleDeleteAchievements',
          reset: 'achievements/handleClearFilter',
          query: 'achievements/handleGetAchievementsByQuery',
          clone: 'achievements/handleGetAchievementToClone',
          status: 'achievements/handleUpdateAchievementsLiveStatus',
        },
      },
      settings: {
        describe: { ...achievements },
        page: { ...achievementsTexts },
        statuses: ['All', 'Draft', 'Ready', 'Active', 'Finished', 'Cancelled', 'Deleted', 'Paused'],
        defaultStatus: 'Active',
      },
      route: {
        create: 'CreateAchievement',
        edit: 'EditAchievement',
        preview: 'PreviewAchievement',
      },
    };
  },
};
</script>
